@font-face {
    font-family: "FuturaBookC";
    src: url("../fonts/futuraBookC/FuturaBookC Regular.woff2") format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 400;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicBook.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 300;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicLight.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 450;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicMedium.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 500;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicDemi.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 600;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicHeavy.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 700;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicBold.woff2) format("woff2");
}

@font-face {
    font-family: futura pt;
    font-style: normal;
    font-weight: 800;
    src:
        local("Futura PT"),
        url(../fonts/futuraPt/FuturaCyrillicExtraBold.woff2) format("woff2");
}
