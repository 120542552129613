@use "fonts.scss";

body {
  margin: 0;
  padding: 0;
  background-color: transparent;

  h1 {
    font-family: FuturaBookC;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 164.87%;
  }

  h2 {
    font-family: FuturaBookC;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
  }

  h3 {
    font-family: FuturaBookC;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
  }

  h4 {
    font-family: FuturaBookC;
  }

  p,
  a {
    font-family: FuturaBookC;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    // line-height: 164.87%;
  }

  b {
    font-family: FuturaBookC;
    font-style: normal;
    font-weight: bold;
    line-height: 115%;

    display: flex;
    align-items: center;
  }
}

// manage zoom

.Main {
  position: fixed;
  text-decoration: none;
  transform-origin: 0 0;
}
